document.addEventListener('DOMContentLoaded', () => {
    const loaderButtons = document.querySelectorAll('.loaders__nav_item');
    const contentContainer = document.querySelector('.loaders__container_items');

    loaderButtons.forEach(function (button) {
        button.addEventListener('click', () => {
            loaderButtons.forEach(function (button) {
                button.classList.remove('loaders__nav_item_active');
            })

            button.classList.add('loaders__nav_item_active');
            const buttonId = button.dataset.id;

            switch (buttonId) {
                case '1':
                    swapContent('0%');
                    break;
                case '2':
                    swapContent('-100%');
                    break;
                case '3':
                    swapContent('-200%');
                    break;
                case '4':
                    swapContent('-300%');
                    break;
                default:
                    swapContent('0%');
            }

            function swapContent(param) {
                contentContainer.style.transform = 'translateX(' + param + ')';
            }
        })
    })
})